import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  margin: 96px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.xl}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 100px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.xl}) {
    width: calc(100% - 16px);
    padding: 8px;
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  width: 50vw;
  max-width: 1060px;
  flex-shrink: 0;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding-top: 20px 8px;
  }
`

const TitleBlock = styled.div`
  margin-right: 60px;

  h1 {
    margin-bottom: 48px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: left;
  }

  p {
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: 63px 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.2rem;
      word-break: break-word;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const ProductDescriptionBlock = styled.div`
  margin-bottom: 20px;
  padding-left: calc(50% - 530px);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    padding: 16px;
  }
`

const GraySpan = styled(Span)`
  background: ${({ theme }) => theme.palette.lightGrey};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;
  margin-top: 16px;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.darkText};
  }
`

const ImageSpan = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 8px;
  flex-wrap: wrap;
`

const ImageSpanImageWrapper = styled.div`
  width: calc(25% - 20px);
  margin: 8px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 20px);
  }
`

const Infobox = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.mainBlue};
  margin-bottom: 25px;
  padding: 30px;
  max-width: 570px;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #000;
  }
  ul {
    list-style-type: none;
    font-size: 0.9rem;
    width: 50%;
  }

  ul li::before {
    content: "☑";
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 25px auto;
  }
`

const InfoboxWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
    padding: 20px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InfoboxFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ul:first-child {
    padding-left: 0;
  }
`

const UpperBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    display: initial;
  }
`

const LowerBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 96px;
  width: 50%;
  flex: 1 1 0%;

  p {
    padding: 20px 0;
    flex: 1 1 0;
  }

  a {
    display: block;
    color: rgb(255, 255, 255);
    background-color: ${({ theme }) => theme.palette.mainBlue};
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    line-height: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 5px;

    :hover {
      filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
    }
  }
`

const Box = styled.div`
  display: flex;
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
  }
`

const LowerBox = styled.div`
  display: flex;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  .gatsby-image-wrapper {
    width: 239px;
    height: 100%;
    object-fit: contain !important;
    object-position: center center !important;

    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.large}) {
      width: calc(100% - 16px);
    }
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`

const BoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
`

const BottomBoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
  display: flex;
  justify-content: center;
`

const BoxComponent = styled.div`
  padding-bottom: 20px;
`

const SingleProductPage = () => (
  <StaticQuery
    query={graphql`
      {
        sektionaltore: file(name: { eq: "SST_Style_Anthrazitgrau_01" }) {
          name
          childImageSharp {
            fluid (maxWidth: 9315, quality: 90){
              src
              aspectRatio
            }
          }
        }
        gridimage1: file(name: { eq: "seitensektionaltor" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SST_Trend_Anthrazitgrau_02|SST_Flair_Verkehrsweiss_01|SST_Style_Anthrazitgrau_02|SST_Style_Graumetallic_01/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER, quality: 100) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
      <Helmet>
        <link rel="canonical" href="https://normstahl.com/de-de/garagentore/sektionaltore/seitensektionaltore" />
      </Helmet>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>
          <ProductDescriptionBlock>
            <Flex direction={"column"}>
              <TitleBlock>
                <h1>SEITEN<wbr/>SEKTIONALTORE</h1>
                <p>
                  Normstahl Seitensektionaltore sind echte Spezialisten für
                  kniffligen Situationen: Bei Grundrissen und Dachschrägen sind
                  sie erste Wahl. Aber auch dann, wenn Sie Ihr Kajak, die
                  komplette Skiausrüstung der Familie oder sperrige Gegenstände
                  unterm Dach verstauen wollen.
                  <br />
                  <br />
                  <ul>
                    <li>Mehr Raum unter der Decke für Sportgeräte, Skiausrüstung und sperrige Güter durch die seitlich zur Wand rollenden Seitenpaneele</li>
                    <li>Einbau in jede Garage möglich – sogar bei Dachschrägen!</li>
                    <li>Direkt-Montage der Renovationsbodenschiene auf den fertigen Fußboden</li>
                    <li>„Kostenlose Türfunktion inklusive“: öffnet sich das Tor seitlich, so genügt bereits ein Spalt und Sie können Ihre Garage betreten.</li>
                    <li>Mehr Platz zum Parken vor der Garage – ideal, wenn das Gebäude dicht an Gehwegen, Straßen gebaut ist.</li>
                    <li>Für jedes Haus und für jeden Geschmack das Richtige – egal ob  Landhaus, Holzhaus, Stadtvilla, Bungalow oder Bauhaus-Stil.</li>
                    <li>10 Jahre Normstahl-Garantie auf das Garagentor inklusive aller Verschleißteile.</li>
                  </ul>
                </p>
              </TitleBlock>

              <ImageWrapper>
                <Img
                  fluid={props.sektionaltore.childImageSharp.fluid}
                  alt="industrietore"
                />
              </ImageWrapper>
            </Flex>
          </ProductDescriptionBlock>

          {/*
          <GraySpan>
            <Margins>
              <p>
                Komfort hat jetzt zwei Aspekte: Bequemlichkeit und Sicherheit.
              </p>
            </Margins>
          </GraySpan>
          */}

          <ImageSpan>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[0].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[1].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[2].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[3].node.fluid}
                alt="industrietore"
              />
            </ImageSpanImageWrapper>
          </ImageSpan>

          <Margins>
            <BoxComponent>
              <UpperBoxWrapper>
                <Box>
                  <TextBlock>
                    <Title>Seiten­sektionaltore</Title>
                    <p>
                      Bei diesem Torsystem rollen die Paneele seitwärts entlang
                      der Wand in die Garage hinein. So ist das
                      Seitensektionaltor eine praktische Alternative für alle,
                      die ihre Garage möglichst bis unter die Decke nutzen
                      möchten. Wenn Sie beispielsweise einen Kajak, die
                      komplette Skiausrüstung oder anderes sperrige Sportgerät
                      oben in Ihrer Garage lagern wollen, dann hält Ihnen das
                      Seitensektionaltor den dafür benötigten Platz frei.
                    </p>
                    <a href="https://normstahl.com/de-de/garagentore/sektionaltore/seitensektionaltore" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                </Box>
                <Box>
                  <TextBlock>
                    <Title> Für Querdenker</Title>
                    <p>
                      Ideal für jeden der häufig eine Schlupftür benötigt oder
                      die komplette Garagenhöhe nutzen will.
                    </p>

                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="smart"
                    />
                  </BoxImageWrapper>
                </Box>
              </UpperBoxWrapper>
            </BoxComponent>
            {/*
            <OuterWrapper>
              <TextWrapper>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet,
                  consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                  invidunt ut labore et dolore magna aliquyam erat, sed diam
                  voluptua. At vero eos et accusam.
                  <br />
                  <br />
                  At vero eos et accusam et justo duo dolores et ea rebum. Stet
                  clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
                  dolor sit amet. Lorem ipsum dolor sit amet, consetetur
                  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                  labore et dolore.
                  <br />
                  <br />
                  Magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                  et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                  no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
              </TextWrapper>

              <InfoboxWrapper>
                <Infobox>
                  <h3>Lorem ipsum dolor sit</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet.
                  </p>
                </Infobox>

                  <Infobox>
                  <h3>Lorem ipsum dolor sit</h3>
                  <InfoboxFlex>
                    <ul>
                      <li>Lorem ipsum dolor sit</li>
                      <li>Consectetur adipiscing</li>
                      <li>Aliquam laoreet ligula</li>
                      <li>Dignissim malesuada</li>
                    </ul>
                    <ul>
                      <li>Lorem ipsum dolor sit</li>
                      <li>Consectetur adipiscing</li>
                      <li>Aliquam laoreet ligula</li>
                      <li>Dignissim malesuada</li>
                    </ul>
                  </InfoboxFlex>
                </Infobox>

              </InfoboxWrapper>
            </OuterWrapper>
            */}
          </Margins>
        </Wrapper>
      </Layout>
    )}
  />
)

export default SingleProductPage
